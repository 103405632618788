<template>
    <bs-popup id="mapping-price-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
        <template v-slot:title>Mapping Price Per Customer Location</template>
        <template v-slot:content>
            <bs-error-message :errors="errorMessages" />
            <div class="mb-3">
                <label for="code" class="form-label">Ship To</label>
                <customer-combo v-model="model.shipToId" :textDefault="model.shipToCode" />
                <bs-input-error :errors="errorMessages" keyError="ShipTo" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Location</label>
                <location-combo v-model="model.locationId" :textDefault="model.locationCode" />
                <bs-input-error :errors="errorMessages" keyError="Location" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Price in Sales Unit (USD)</label>
                <bs-currency v-model="model.price" />
                <bs-input-error :errors="errorMessages" keyError="Price" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Sales Unit</label>
                <unit-combo v-model="model.unitId" :textDefault="model.unitCode"  />
                <bs-input-error :errors="errorMessages" keyError="Unit" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Valid From</label>
                <bs-date-picker v-model="model.validFrom" />
                <bs-input-error :errors="errorMessages" keyError="ValidFrom" />
            </div>

            <div class="mb-3">
                <label for="code" class="form-label">Valid To</label>
                <bs-date-picker v-model="model.validUntil" />
                <bs-input-error :errors="errorMessages" keyError="ValidUntil" />
            </div>

        </template>
        <template v-slot:footer>
            <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
            <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
        </template>
    </bs-popup>
</template>

<script>
import MappingPriceService from "@/services/mapping-price-service.js";

export default {
    data: function() {
        return {
            id: 0,
            model: MappingPriceService.default(),
            errorMessages: null
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if (id !== undefined && id !== null && id !== 0) {
            this.id = id;
            this.loadData();
        }
    },
    methods: {
        async loadData() {
            if (this.id === undefined || this.id === null || this.id === 0) 
            {
                return;
            }

            const result = await MappingPriceService.getObject(this.id);
            if (result.status) {
                this.errorMessages = result.errorMessages;
            }
            else {
                this.errorMessages = null;
            }

            this.model = result.data;
        },
        async onPopupClosed() {
            this.$router.push('/mapping-price');
        },
        async close() {
            this.$refs.form.closePopup();
        },
        async saveData() 
        {
            try {
                this.$store.commit('progressCounterQueueIncrement');
                
                let status = false;
                if (this.id !== undefined && this.id !== null && this.id !== 0) 
                {
                    const result = await MappingPriceService.update(this.model, this.id);
                    status = result.status;
                    if (status) {
                        this.errorMessages = null;
                    }
                    else{
                        this.errorMessages = result.errorMessages;
                    }
                }
                else 
                {
                    const result = await MappingPriceService.create(this.model);
                    status = result.status;
                    if (status) {
                        this.errorMessages = null;
                        this.id = result.data.id;
                    }
                    else{
                        this.errorMessages = result.errorMessages;
                    }
                }

                if (!status) return;

                this.$refs.form.closePopup();
                this.$router.push(`/mapping-price/detail/${this.id}`);
            }
            finally {
                this.$store.commit('progressCounterQueueDecrement');
            }
        }
    }
}
</script>